import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/app-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/theme-provider.tsx");
